/**
 * @file
 * Provides Slick loader.
 */
 import './slick.css';

 (($, context) => {
  'use strict';

  let booleanKeys = [
    'accessibility',
    'adaptiveHeight',
    'autoplay',
    'arrows',
    'centerMode',
    'dots',
    'draggable',
    'fade',
    'fadeOnSelect',
    'infinite',
    'mobileFirst',
    'pauseOnFocus',
    'pauseOnHover',
    'pauseOnDotsHover',
    'swipeToSlide',
    'touchMove',
    'variableWidth',
    'vertical',
    'verticalSwiping',
    'rtl',
    'waitForAnimate',
  ];

  function doSlick(i, element) {
    let config = $(element).data('slick');

    if (config) {
      config = normalizeConfig(config);
    }

    if(config.hasOwnProperty('initialSlide') && config['initialSlide'] === 'rand'){
      let numChildren = $(element).children().length - 1;
      let initialSlide = Math.floor(Math.random() * (numChildren + 1));
      config['initialSlide'] = initialSlide;
    }

    if(window.defaultSlick) {
      config = Object.assign(window.defaultSlick, config);
    }

    if ($(element).hasClass('slick-initialized')) {
      $(element).slick('destroy');
    }   

    if(config.hasOwnProperty('count') && config['count']) {
      // console.log($count);
      $(element).on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
        let $count =  $('[data-slick-count]', element);
        if($count.length === 0) {
          let $count = $(element).next().find('[data-slick-count]');
        }
        
        // currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        let i = (currentSlide ? currentSlide : 0) + 1;
        $count.text(i + '/' + slick.slideCount);
      });
    }

    if(config.hasOwnProperty('externalNav') && config['externalNav']) {
      let $navigation = $(config['externalNav']);
      $('[data-slick-page]', $navigation).on('click', (event)=>{
        if($(element).hasClass('slick-initialized')){
          let $index = $(event.currentTarget).data('slick-page');
          $(element).slick('slickGoTo', $index, true);
        }
      });
    }

    $(element).slick(config);
    
    $(element).on('slick-reinit', ()=>{
      $(element).slick('unslick');
      $(element).slick(config);
    })
  }

  function doSlickNav(i, element) {
    let config = $(element).data('slick-nav');
    if (config) {
      config = normalizeConfig(config);
    }

    $(element).slick(config);
  }

  // PHP prints booleans as 1/0, slick needs these values to be true/false. 
  // The booleanKeys array is to specify which options should be a boolean as some options
  // can be set to 0 and not be a boolean.
  function normalizeConfig(config) {
    Object.keys(config).forEach((key)=>{
      config[key] = (booleanKeys.includes(key)) ? Boolean(config[key]) : config[key];
    });
    return config;
  }


  $(window).on('load ajaxComplete', ()=>{
    $('[data-slick]').each(doSlick);
  })
    
})(jQuery, jQuery(document));
